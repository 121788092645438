<template>
  <div class="infos" :class="{ old: isElder }">
    <div class="top">
      <div class="infos-title">{{ scenicSpotData.scenicSpotName }}</div>

      <div class="more" @click="$router.push('/industryMap/detail')">
        查看更多 >
      </div>
    </div>
    <div class="infos-main">
      <div>运营单位：桐庐垂云通天河旅游公司</div>
      <div>安全风险评估单位：河南鑫安利职业健康科技有限公司</div>
      <div>建设地点：{{ scenicSpotData.areaName || '无'}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "sceneInfo",
  props: {
    scenicSpotData: {
      type: Object,
    },
  },
  computed: {
    isElder() {
      return this.$store.getters.getIsElder;
    },
  },
};
</script>
<style lang="less" scoped>
.infos {
  position: absolute;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  min-height: 20%;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  padding: 13px;
  .top {
    display: flex;
    justify-content: space-between;
    .more {
      color: #fff;
      width: 80px;
      font-size: 14px;
      height: 26px;
      line-height: 26px;
      border-radius: 10px;
      border: 1px solid #fff;
      text-align: center;
      a {
        color: #fff;
      }
    }
    .infos-title {
      font-size: 18px;
      color: #fff;
      line-height: 24px;
      &::after {
        content: "";
        display: block;
        background-color: #fff;
        width: 50px;
        height: 3px;
        border-radius: 2px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
  &-main {
    font-size: 14px;
    line-height: 26px;
    color: #fff;
  }
}
.old {
  .infos-title {
    font-size: 22px !important;
  }
  .more {
    width: 105px !important;
    height: 32px;
    line-height: 28px;
    font-size: 18px !important;
  }
  .infos-main {
    font-size: 18px;
  }
}
</style>
