<template>
  <div class="oneMap">
    <div id="map"></div>
    <sceneInfo></sceneInfo>
  </div>
</template>
<script>
import mapMixin from "@/mixins/mapMixin.vue";
import request from "@/api/index";
import sceneInfo from "./components/sceneInfo";
export default {
  mixins: [mapMixin],
  components: {
    sceneInfo,
  },
  data() {
    return {};
  },
  methods: {
    // 获取设施数据
    getAreaData(id) {
      request
        .post2("getScenicSpotCoordinates", { id })
        .then((res) => {
          const data = res.data;
          if (typeof data == "string") {
            this.$msgError(data);
            return;
          }
          const { scenicSpotScope = "", facilityCoordinatesVOS = [] } = data;

          if (scenicSpotScope == null) {
            return;
          }
          let scenicSpotScopedata = this.areaFormatter(scenicSpotScope);
          // let area = scenicSpotScopedata.map((i) => {
          //   return new T.LngLat(i[0], i[1]);
          // });
          // this.setCenterPoint(area);
          // this.addAreaLayer([this.areaFormatters(scenicSpotScope)], {});
          this.addAreaLayer([scenicSpotScopedata], {
            center: [119.5280672610001, 29.93092132200007],
            zoom: 17,
          });
          // let color = {
          //   color: "#ffffff",
          //   weight: 0,
          //   opacity: 0.8,
          //   fillColor: "#3366FF",
          //   fillOpacity: 0.5,
          // };
          // let polyArea = new T.Polygon(area, color);
          // this.setPoly(polyArea);
          // let icon = new T.Icon({
          //   iconUrl: require("@/assets/industryMap/sheshidian.png"),
          //   iconSize: new T.Point(40, 40),
          //   iconAnchor: new T.Point(40, 40),
          // });
          this.setMarkersoneMap(
            facilityCoordinatesVOS,
            require("@/assets/industryMap/sheshidian.png")
          );
          // this.setMarkers(facilityCoordinatesVOS, { icon: icon });
          // this.setLabelMarkers(facilityCoordinatesVOS);
        });
    },
  },
  // created() {
  //   window.addEventListener("reload", this.$router.push('/industryMap'))
  // },
  mounted() {
    this.initMap();
    this.getAreaData(this.$route.query.id);
  },
  watch: {
    "$route.query": {
      handler: function (newVal) {
        this.getAreaData(newVal.id || 1);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="less" scope>
.oneMap {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  #map {
    width: 100%;
    height: 100%;
    .tdt-label {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
    }
  }
  // 隐藏天地图
  .tdt-touch .tdt-bar,
  .tdt-touch .tdt-control-copyright,
  .tdt-touch .tdt-control-layers {
    display: none;
  }
}
</style>
